$(document).ready(function () {
  const dateLimit = '<?php echo wp_kses_post( $alert_message_limit_date ); ?>';
  if (
    !document.cookie.split('; ').find((row) => row.startsWith('messageAlert'))
  ) {
    $('.alert-message').addClass('open');
  }

  $('.close-alert').click(function () {
    $('.alert-message')
      .slideUp(300, function () {})
      .fadeOut({
        duration: 300,
        queue: false,
      })
      .removeClass('open');

    document.cookie = 'messageAlert=true; expires=' + dateLimit + '';
  });
});
