(function () {
  const embedVideo = document.querySelector('.is-style-video-styled');
  if (!embedVideo) {
    return;
  }
  embedVideo.addEventListener('click', () => {
    document.querySelector('.is-style-video-styled').classList.add('active');
    document.querySelector('.is-style-video-styled iframe').src +=
      '&autoplay=1';
  });
})(jQuery); // Fully reference jQuery after this point.
