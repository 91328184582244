import domReady from '@wordpress/dom-ready';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const shareButton = document.querySelector('.shareButton');

  if (shareButton) {
    shareButton.addEventListener('click', handleShareBtn);
  }

  function handleShareBtn(e) {
    e.preventDefault();

    const args = {
      url: window.location.href,
      text: _x('I want to share this with you.', 'Share text', 'vtx'),
      title: document.getElementsByTagName('title')[0].innerHTML,
    };

    navigator.share(args);
  }
});
