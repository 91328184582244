import Swiper from 'swiper';
window.addEventListener('load', function () {
  //swiper search-bar-request
  new Swiper('.banner-home__search-bar__request__wrapper', {
    // Optional parameters
    slidesPerView: 'auto',
    watchOverflow: true,
    direction: 'horizontal',
    spaceBetween: 20,
    navigation: {
      nextEl: '.search-bar-next',
      prevEl: '.search-bar-prev',
    },
  });
});
