$(document).ready(function () {
  $('.is-style-btn-anim .wp-block-button__link').addClass('btn--anim');
  $('.is-style-btn-anim a').addClass('btn--anim');

  const htmlBtnsAnim = $('.btn--anim');

  htmlBtnsAnim.each(function (index, element) {
    const btnTxtAttribute = element.innerHTML;
    const btnSpan =
      '<span>' +
      btnTxtAttribute +
      "</span><div class='marquee' aria-hidden='true'><div class='marquee__inner'><span>" +
      btnTxtAttribute +
      '</span><span>' +
      btnTxtAttribute +
      '</span><span>' +
      btnTxtAttribute +
      '</span><span>' +
      btnTxtAttribute +
      '</span></div></div>';
    element.innerHTML = btnSpan;
  });
});

if ($('.newsletter_btn_wrapper').length > 0) {
  ////// Hide sticky newsletter if footer is visible
  window.addEventListener('scroll', function () {
    const element = document.querySelector('.site-footer');
    const position = element.getBoundingClientRect();
    // checking for partial visibility
    if (position.top < window.innerHeight && position.bottom >= 0) {
      document
        .querySelector('.newsletter_btn_wrapper')
        .classList.add('btn_hidden');
    } else {
      document
        .querySelector('.newsletter_btn_wrapper')
        .classList.remove('btn_hidden');
    }
  });
}
