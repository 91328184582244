import Swiper from 'swiper';
const CarouselContainer = document.querySelectorAll('.partners_logos__list');

/* Swiper
 **************************************************************/
let swiper = window.swiper;
let init = false;

/* Which media query
 **************************************************************/
function swiperMode() {
  const mobile = window.matchMedia('(min-width: 0px) and (max-width: 768px)');
  const tablet = window.matchMedia(
    '(min-width: 769px) and (max-width: 1024px)'
  );
  const desktop = window.matchMedia('(min-width: 1025px)');

  // Enable (for mobile)
  if (mobile.matches) {
    if (!init) {
      init = true;

      swiper = new Swiper('.partners_logos__list', {
        direction: 'horizontal',
        loop: true,
        // Disable preloading of all images
        preloadImages: false,
        // Enable lazy loading
        lazy: true,
        mousewheel: {
          enabled: true,
          forceToAxis: true,
        },
        keyboard: true,
        breakpoints: {
          200: {
            slidesPerView: 2.5,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 4.5,
            spaceBetween: 50,
          },
        },
      });
    }
  }

  // Disable (for tablet)
  else if (tablet.matches) {
    if (init) {
      swiper.destroy();
      init = false;
    }
  }

  // Disable (for desktop)
  else if (desktop.matches) {
    if (init) {
      swiper.destroy();
      init = false;
    }
  }
}

/* On Load
 **************************************************************/
window.addEventListener('load', function () {
  if (CarouselContainer) {
    swiperMode();
  }
});

/* On Resize
 **************************************************************/
window.addEventListener('resize', function () {
  if (CarouselContainer) {
    swiperMode();
  }
});
